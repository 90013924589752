import React from "react";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import HeroSection from "../components/HeroSection";
import Features from "../components/Features";
import Footer from "../components/Footer";

const HomePage = () => {
  return (
    <>
      <Header />
      <NavBar />
      <HeroSection />
      <Features />
      <Footer />
    </>
  );
};

export default HomePage;
