import React from "react";
import { Link } from "react-router-dom";
import '../styles/styles.css';

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/">
        <img src="/images/logo.png" alt="Savrly Logo" className="nav-logo" />
      </Link>
      <Link to="/">Home</Link>
      <Link to="/signup">Sign Up</Link>
      <a href="#about">About Us</a>
      <a href="#contact">Contact</a>
    </nav>
  );
};

export default NavBar;
