import React from "react";
import Header from "../components/Header";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import "../styles/styles.css";

const SignUpPage = () => {
  return (
    <>
      <Header />
      <NavBar />
      <div className="signup-container">
        <h2>Sign up for Savings Alerts</h2>
        <p>Get the latest grocery price updates and save more!</p>
        <form className="signup-form">
          <input
            type="email"
            placeholder="Enter your email"
            className="email-input"
          />
          <button type="submit" className="signup-button">
            Sign Up
          </button>
        </form>
      </div>
      <Footer />
    </>
  );
};

export default SignUpPage;
