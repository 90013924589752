import React from "react";

const Features = () => {
  return (
    <section className="features">
      <div>
        <h3>Real-Time Prices</h3>
        <p>Instantly check the prices of items in stores near you.</p>
      </div>
      <div>
        <h3>Track Savings</h3>
        <p>Monitor how much you’re saving with our smart tools.</p>
      </div>
      <div>
        <h3>User-Friendly</h3>
        <p>Easy to use and built with you in mind for the best experience.</p>
      </div>
    </section>
  );
};

export default Features;
