import React from "react";
import { Link } from "react-router-dom";
import '../styles/styles.css';

const HeroSection = () => {
  return (
    <section className="hero">
      <img src="/images/logo.png" alt="Savrly Logo" className="logo" />

      <h1>Track Grocery Prices Near You with Savrly</h1>
      <p>
        User-powered grocery price tracker launching by the end of this year.
        Join us now and be the first to save on your groceries! Input prices, search for deals, and shop smarter.
      </p>
      <Link to="/signup" className="cta-button">
        Sign Up for Early Access
      </Link>
    </section>
  );
};

export default HeroSection;
